const config = require(`../../../../libs/sites/${process.env.GATSBY_SITENAME}/src/config.json`)

export const onClientEntry = () => {
  // if (config.fonts) {
  //   Object.values(config.fonts)?.forEach((font: string) => {
  //     require(font)
  //   })
  // }
  require('fontsource-caudex')
  // require('fontsource-lora')
}
