import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'
import Config from '../config.json'

export const Link = styled(GatsbyLink)`
  padding: 0 10px;
  color: ${({ transparent = false }: any) => (transparent ? '#da1a27' : '#000000')};
  ${({ transparent = false }: any) => (!transparent ? '&:hover { color: #721e2e }' : null)};
  white-space: nowrap;
  font-family: Playfair Display;
  text-decoration: none;
  text-transform: uppercase;
`

const NavigationContainer = styled.header`
  width: 100%;
  padding: 8px 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  background: ${({ transparent }: any) => (transparent ? 'transparent' : '#fff')};
  box-shadow: ${({ transparent }: any) => (transparent ? 'none' : '0 0 3px 0 rgba(0, 0, 0, 0.22)')};
`

const NavBar = styled.nav`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Title = styled.h1`
  font-size: 40px;
  margin: 0;
  text-align: center;
`

export const Navigation = ({ transparent }) => {
  return (
    <NavigationContainer {...{ transparent }}>
      <Title>
        <Link {...{ transparent, to: '/' }}>Duo Kathrein</Link>
      </Title>
      <NavBar>
        {Config.navigation.items.map(({ url, title }, key) => {
          return <Link {...{ key, transparent, to: url, children: title }} />
        })}
      </NavBar>
    </NavigationContainer>
  )
}
