import * as React from 'react'
import { Link, WrapPageElementBrowserArgs } from 'gatsby'
// import { NavigationBar } from '@jfa/ui-layout'
// import { SearchField } from '@jfa/ui'

// console.log(NavigationBar)
// import * as fs from 'fs'

// const siteLayoutPath = `../../../../libs/sites/${process.env.GATSBY_SITENAME}/src/layout/index`
// const LayoutModule = fs.existsSync(siteLayoutPath) ? require(siteLayoutPath) : require('../defaults/Layout.tsx')

// const layoutPath = fs.existsSync(`../../../../libs/sites/${process.env.GATSBY_SITENAME}/src/layout/index`)
const { Layout } = require(`../../../../libs/sites/${process.env.GATSBY_SITENAME}/src/layout/index`)

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
  return <Layout {...{ ...props, LinkComponent: Link }}>{element}</Layout>
}

