import * as ReactDOM from 'react-dom'
export { wrapPageElement } from './src/gatsby/wrapPageElement'
export { onClientEntry } from './src/gatsby/onClientEntry'
// console.log(wrapPageElement)

// export const wrapPageElement = wrapPageElement
// export const onClientEntry = () => {
//   require('fontsource-playfair-display')
//   require('fontsource-lora')
// }

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.unstable_createRoot(container, {
      hydrate: true,
      hydrationOptions: { onHydrated: callback }
    }).render(element)
  }
}
