import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Navigation } from './Navigation'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

import './styles.css'
const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        margin: 0;
      }

      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }

      h1 {
        font-size: 2em;
      }
    `}
  />
)

const LayoutWrapper = styled.section`
  width: 100%;
  max-width: 100%;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`

const ElementWrapper = styled.main`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 16px;
  overflow-y: scroll;
  box-sizing: border-box;

  flex: 1;
  display: flex;
  flex-direction: column;
  & *:not(:last-child) {
    flex: 1;
  }
`

const Footer = styled.footer`
  flex: 0;
  font-size: 12px;
  padding: 0 8px;
  color: ${({ transparent }: any) => (transparent ? '#da1a27' : '#000000')};
`

export const Layout = ({ location, children }) => {
  const transparent = location.pathname === '/'
  return (
    <LayoutWrapper>
      <GlobalStyle />
      <Helmet
        title="Duo Kathrein"
        meta={[
          { name: 'description', content: 'Duo Kathrein' },
          {
            name: 'keywords',
            content: 'kathrein, anne-kathrein, jordan, allenberg, bloess'
          }
        ]}
      />
      <Navigation {...{ transparent }} />
      <ElementWrapper>
        {children}
        <Footer {...{ transparent }}>© {new Date().getFullYear()} Duo Kathrein</Footer>
      </ElementWrapper>
    </LayoutWrapper>
  )
}
